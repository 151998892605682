@import '@styles/variables/colors';

.container {
  padding: 0 5rem 1.25rem;
  background: $background-main;

  .column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-basis: 33%;

    .title {
      color: $text-third;
      font-weight: 500;
      font-size: 0.875rem;
      margin-bottom: 0.875rem;
    }

    & > a,
    & > span {
      display: inline-flex;
      align-items: center;
      color: $text-primary;
      text-decoration: none;
      font-size: 0.75rem;
      line-height: 1.25rem;

      &.file {
        position: relative;

        &::after {
          position: absolute;
          content: '';
          bottom: 2px;
          left: 0;
          width: 100%;
          height: 1px;
          background: $text-primary;
        }
      }

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      i {
        margin-right: 0.75rem;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
