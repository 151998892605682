@import './fonts';
@import './typography';
@import './spacing';
@import './flex';
@import './form';

* {
  font-family: 'Golos Text VF', sans-serif;
}

html {
  background-color: $background-main;
  font-size: 16px;

  @media screen and (max-width: 1100px) {
    font-size: 14px;
  }
}

body {
  color: $text-primary;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
