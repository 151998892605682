.p-checkbox {
  width: 1.25rem;
  height: 1.25rem;

  .p-checkbox-box {
    width: 1.25rem;
    height: 1.25rem;
    border-color: $icons-ghost;

    &.p-highlight {
      background: $main-primary;
      border-color: $main-primary;
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &:hover {
        border-color: $main-primary;
      }

      &.p-highlight {
        &:hover {
          background: $main-primary;
          border-color: $main-primary;
        }
      }

      &.p-focus {
        box-shadow: none;
        border-color: $main-primary;
      }
    }
  }
}
