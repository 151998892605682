.p-dialog {
  &.p-dialog-default {
    width: 31rem;

    .p-dialog-header {
      padding: 1.5rem;
      padding-bottom: 0.75rem;

      .p-dialog-title {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 2rem;
        color: $text-primary;
      }

      .p-dialog-header-icons {
        display: none;
      }
    }

    .p-dialog-content {
      padding: 1.5rem;
      padding-top: 0;
    }
  }

  &.p-confirm-dialog {
    width: 25rem;

    .p-dialog-header {
      padding: 1.5rem;
      padding-bottom: 0.25rem;

      .p-dialog-title {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 2rem;
        color: $text-primary;
      }

      .p-dialog-header-icons {
        display: none;
      }
    }

    .p-dialog-content {
      padding: 0 1.5rem;
      padding-bottom: 1.5rem;

      .p-confirm-dialog-message {
        font-size: 0.875rem;
        color: $text-secondary;
        margin: 0;
      }
    }

    .p-dialog-footer {
      display: flex;
      justify-content: flex-end;

      .p-confirm-dialog-reject {
        background-color: rgba($text-accent, 0.08);
        color: $text-accent;
        margin-right: 0.75rem;

        &:hover {
          background-color: rgba($text-accent, 0.08) !important;
          color: $text-accent !important;
        }
      }

      .p-confirm-dialog-accept {
        margin-right: 0;
      }

      button {
        padding: 0.625rem 1rem;
        font-size: 1rem;

        .p-button-label {
          font-weight: 500;
        }
      }
    }
  }
}
