@import '@styles/variables/colors';

.image {
  min-width: 8rem;
  max-width: 8rem;
  min-height: 8rem;
  margin-right: 1.5rem;
  border-radius: 0.75rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.limitations {
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
  color: $text-third;
  font-size: 0.875rem;

  .limitation {
    position: relative;
    padding-left: 1.25rem;

    &::before {
      position: absolute;
      top: 50%;
      left: 0.5rem;
      content: '';
      width: 3px;
      height: 3px;
      background: $text-third;
      border-radius: 50%;
      transform: translateY(-50%);
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}
