@import '@styles/variables/colors';

.layout {
  min-height: 100vh;
  display: flex;
  gap: 1.5rem;
  background: $background-main;
  padding: 5rem;

  .sidebar {
    width: 18.75rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media screen and (max-width: 1200px) {
      width: 15rem;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 2.5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 24px rgba(24, 33, 43, 0.04);
    background: $main-white;
    flex: 1;
    overflow-x: auto;

    .title {
      display: flex;
      align-items: center;
      padding-right: 10rem;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.25;
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 2.5rem;
  }
}
