@import '@styles/variables/colors';

.card {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 26.875rem;
  padding: 1.5rem;
  background: $main-secondary;
  border-radius: 0.75rem;
}
