.p-selectbutton {
  background: #e9edf0;
  padding: 0.25rem;
  border-radius: 0.375rem;

  .p-button {
    background: #e9edf0;
    color: $text-third;
    font-size: 0.875rem;
    padding: 0.375rem 0.75rem;
    height: auto;

    .p-button-label {
      font-weight: 500;
    }

    &:hover,
    &:focus,
    &:active {
      background: #e9edf0 !important;
      color: $text-third !important;
    }

    &.p-highlight {
      background: $main-white !important;
      color: $text-primary !important;
      border-radius: 0.25rem;
      box-shadow: 4px 0px 16px rgba(24, 33, 43, 0.08);
      pointer-events: none;

      &:hover,
      &:hover,
      &:active {
        background: $main-white !important;
        color: $text-primary !important;
      }
    }
  }
}
