.f-icon {
  position: absolute;
  top: 50%;
  pointer-events: none;
}
.f-icon-r {
  right: 1rem;
  transform: translateY(-50%);
}

.p-calendar {
  & + i {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.p-datepicker-prefix {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  transform: translateY(-50%);
  z-index: 2;
}
