.p-datepicker {
  color: $text-primary !important;
  padding: 0.75rem 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 24px rgba(24, 33, 43, 0.04);
  margin: 0.25rem 0;
  min-width: 22rem !important;

  &.p-datepicker-timeonly {
    min-width: 12rem !important;
  }

  .p-timepicker {
    padding: 0;
    font-weight: 400;

    .p-separator {
      padding: 0;

      span {
        font-size: 1rem;
        margin-bottom: 0.25rem;
      }
    }

    .p-hour-picker,
    .p-minute-picker {
      & > span {
        font-size: 1rem;
      }

      & > button {
        color: $text-primary;
        width: 1.75rem;
        height: 1.75rem;

        span {
          font-size: 1rem !important;
        }
      }
    }
  }

  .p-yearpicker {
    margin: 0;

    .p-yearpicker-year {
      margin: 0.25rem;
      padding: 0;
      width: 47%;
      height: 3rem;
      box-shadow: none !important;

      &:not(.p-highlight):not(.p-disabled):hover {
        background: $form-default !important;
      }

      &.p-highlight {
        background: $form-default;
        color: $main-primary;
      }
    }
  }

  .p-monthpicker {
    margin: 0;

    .p-monthpicker-month {
      margin: 0.25rem;
      padding: 0;
      width: 30%;
      height: 3rem;
      box-shadow: none !important;

      &:not(.p-highlight):not(.p-disabled):hover {
        background: $form-default !important;
      }

      &.p-highlight {
        background: $form-default;
        color: $main-primary;
      }
    }
  }

  .p-datepicker-header {
    margin-bottom: 0.25rem;
    padding: 0;
    padding-bottom: 0.625rem;
    color: $text-primary;

    .p-datepicker-prev,
    .p-datepicker-next {
      color: $text-primary;
      box-shadow: none !important;

      &:enabled:hover {
        background: $form-default;
      }
    }

    .p-datepicker-title {
      .p-datepicker-month,
      .p-datepicker-year {
        color: $text-primary;
        margin: 0;
        padding: 0.25rem;
        font-weight: 500;
        font-family: 'Golos Text VF', sans-serif;

        &:hover {
          color: $text-accent !important;
        }
      }
    }
  }

  .p-datepicker-calendar {
    margin: 0;

    thead {
      th {
        font-weight: 500;
        padding: 0.25rem;

        span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    td {
      padding: 0.25rem;

      &.p-datepicker-today {
        span {
          background: transparent;
          font-weight: 500;
        }
      }

      span {
        border: none;
        box-shadow: none !important;

        &:not(.p-highlight):not(.p-disabled):hover {
          background: $form-default !important;
        }

        &.p-highlight {
          background: $form-default;
          color: $main-primary;
        }
      }
    }
  }
}
