.p-tag {
  display: inline-block;
  padding: 0.125rem 0.5rem;
  background: rgba($text-accent, 0.08);
  font-size: 0.75rem;
  font-weight: 500;
  color: $text-accent;
  border-radius: 0.25rem;
  line-height: 1.25rem;
  white-space: nowrap;
}
