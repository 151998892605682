.p-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.75rem;
  background: $main-primary;
  border-radius: 0.5rem;
  border: none !important;
  padding: 0.625rem 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  transition: background 0.2s ease-out !important;

  i {
    color: $main-white !important;
  }

  .p-button-loading-icon {
    margin-right: 0.5rem;
  }

  &:hover {
    background: $button-primary-hover !important;
    transition: background 0.1s ease-in !important;
  }

  &:focus,
  &:active {
    background: $button-primary-focus !important;
    box-shadow: none !important;
  }

  .p-button-icon-right {
    margin-left: 0.75rem;
  }

  .p-button-icon-left {
    margin-right: 0.75rem;
  }

  &.p-button-text {
    color: $main-primary;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0;
    height: auto;
    width: auto;
    transition: color 0.2s ease-out !important;

    i {
      color: $main-primary !important;
    }

    &:hover {
      color: $button-primary-hover !important;
      background: transparent !important;
      transition: color 0.1s ease-in !important;

      i {
        color: $button-primary-hover !important;
      }
    }

    &:focus,
    &:active {
      color: $button-primary-focus !important;
      background: transparent !important;

      i {
        color: $button-primary-focus !important;
      }
    }
  }

  &.p-button-icon-only {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.75rem;
    min-height: 2.75rem;
    max-width: 2.75rem;
    max-height: 2.75rem;
    padding: 0.875rem;
    border-radius: 0.375rem;
  }

  &.p-button-secondary {
    background: $main-white;
    border: 1px solid rgba(#dbe0e5, 0.8) !important;
    color: $text-primary;
    box-shadow: 0px 4px 16px rgba(24, 33, 43, 0.06);

    i {
      color: $text-primary !important;
    }

    &:hover {
      background: $button-secondary-hover !important;
      border-color: rgba(#dbe0e5, 0.8) !important;
      color: $text-primary !important;
    }

    &:focus,
    &:active {
      background: $button-secondary-focus !important;
      color: $text-primary !important;
      box-shadow: 0px 4px 16px rgba(24, 33, 43, 0.06) !important;
    }
  }

  &.p-button-info {
    background: rgba($text-accent, 0.08);
    color: $text-accent;

    i {
      color: $text-accent !important;
    }

    &:hover {
      background: rgba($text-accent, 0.16) !important;
      color: $text-accent !important;
    }

    &:focus,
    &:active {
      background: rgba($text-accent, 0.24) !important;
      color: $text-accent !important;
    }
  }

  &.p-button-help {
    background: $main-secondary;

    i {
      color: $main-white !important;
    }

    &:hover {
      background: $button-help-hover !important;
    }

    &:focus,
    &:active {
      background: $button-help-focus !important;
    }
  }

  &.p-button-danger {
    &.p-button-text {
      color: $main-error;

      i {
        color: $main-error;
      }

      &:hover {
        color: $button-danger-hover !important;

        i {
          color: $button-danger-hover !important;
        }
      }

      &:focus,
      &:active {
        color: $button-danger-focus !important;

        i {
          color: $button-danger-focus !important;
        }
      }
    }
  }

  &.p-button-white {
    background: $main-white;
    color: $text-primary;

    &:hover {
      background: darken($main-white, 3%) !important;
      color: $text-primary !important;
    }

    &:focus,
    &:active {
      background: darken($main-white, 5%) !important;
      color: $text-primary !important;
    }
  }

  &.p-button-menu {
    width: 100% !important;
    justify-content: flex-start;
    color: $text-primary;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.5rem 0.75rem;
    height: auto;
    width: auto;
    background: transparent;
    border-radius: 0.375rem;
    transition: background 0.15s ease-out;

    i {
      color: $icons-ghost !important;
      transition: color 0.15s ease-out;
    }

    &:hover {
      background: $form-default !important;
      transition: background 0.1s ease-in !important;
      color: $text-primary;

      i {
        color: $icons-active !important;
        transition: color 0.1s ease-in;
      }
    }
  }
}
