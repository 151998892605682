.field-radiobutton {
  cursor: pointer;
}
.p-radiobutton {
  .p-radiobutton-box {
    border-color: $icons-ghost;

    &:not(.p-disabled).p-focus {
      box-shadow: none !important;
    }

    &:not(.p-disabled):not(.p-highlight) {
      &:hover,
      &.p-focus {
        border-color: $icons-active !important;
      }
    }

    &.p-highlight {
      background: transparent;
      border-color: $main-primary !important;

      &:not(.p-disabled):hover {
        background: transparent;
        border-color: $button-primary-hover;

        .p-radiobutton-icon {
          background: $button-primary-hover;
          width: 0.875rem;
          height: 0.875rem;
        }
      }

      .p-radiobutton-icon {
        background: $main-primary;
        width: 0.875rem;
        height: 0.875rem;
      }
    }
  }
}
