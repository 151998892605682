.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &.right {
    right: 1rem;
  }

  &.left {
    left: 1rem;
  }
}

.clear {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  cursor: pointer;

  &.withIcon {
    right: 2.75rem;
  }
}

.loading {
  min-height: 2.75rem;
  min-width: 10rem;
}
