@font-face {
    font-family: "icons";
    src: url("./icons.eot?c42869c665c6be733c4b03a1449e05da#iefix") format("embedded-opentype"),
url("./icons.woff2?c42869c665c6be733c4b03a1449e05da") format("woff2"),
url("./icons.woff?c42869c665c6be733c4b03a1449e05da") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
    content: "\f101";
}
.icon-arrow-up:before {
    content: "\f102";
}
.icon-bag:before {
    content: "\f103";
}
.icon-block:before {
    content: "\f104";
}
.icon-brush:before {
    content: "\f105";
}
.icon-calendar:before {
    content: "\f106";
}
.icon-check-circle:before {
    content: "\f107";
}
.icon-check:before {
    content: "\f108";
}
.icon-chevron-down:before {
    content: "\f109";
}
.icon-chevron-left:before {
    content: "\f10a";
}
.icon-chevron-right:before {
    content: "\f10b";
}
.icon-clock:before {
    content: "\f10c";
}
.icon-cross:before {
    content: "\f10d";
}
.icon-diagram:before {
    content: "\f10e";
}
.icon-exclamation-circle:before {
    content: "\f10f";
}
.icon-eye-closed:before {
    content: "\f110";
}
.icon-eye-opened:before {
    content: "\f111";
}
.icon-form:before {
    content: "\f112";
}
.icon-gear:before {
    content: "\f113";
}
.icon-headphones:before {
    content: "\f114";
}
.icon-info-circle:before {
    content: "\f115";
}
.icon-location:before {
    content: "\f116";
}
.icon-lock:before {
    content: "\f117";
}
.icon-mail:before {
    content: "\f118";
}
.icon-moderation:before {
    content: "\f119";
}
.icon-pencil:before {
    content: "\f11a";
}
.icon-phone:before {
    content: "\f11b";
}
.icon-plus:before {
    content: "\f11c";
}
.icon-reject:before {
    content: "\f11d";
}
.icon-restore:before {
    content: "\f11e";
}
.icon-search:before {
    content: "\f11f";
}
.icon-sign-out:before {
    content: "\f120";
}
.icon-transaction:before {
    content: "\f121";
}
.icon-trash:before {
    content: "\f122";
}
.icon-unlock:before {
    content: "\f123";
}
.icon-upload:before {
    content: "\f124";
}
.icon-user:before {
    content: "\f125";
}
.icon-users:before {
    content: "\f126";
}
.icon-wallet:before {
    content: "\f127";
}
.icon-warning:before {
    content: "\f128";
}
.icon-zoom-in:before {
    content: "\f129";
}
