.p-datatable {
  max-width: 100%;

  .p-datatable-wrapper {
    overflow-x: auto;
  }

  .p-datatable-thead {
    tr {
      th {
        padding: 0.75rem 1rem;
        background: $main-grey;
        color: $text-third;
        font-weight: 500;
        border: 1px solid $main-grey;
        border-bottom-color: $divider-default;
        font-size: 0.875rem;
        white-space: nowrap;
      }
    }
  }

  .p-datatable-tbody {
    .p-datatable-emptymessage {
      &:hover {
        td {
          background: $main-white;
        }
      }
    }

    tr {
      color: $text-primary;

      &:hover {
        td {
          background: $form-default;
          transition: background 0.1s ease-in;
        }
      }

      &.p-highlight {
        color: $text-primary;
      }

      &:first-child {
        td {
          border-top-width: 0;
        }
      }

      td {
        padding: 0.75rem 1rem;
        background: $main-white;
        border-width: 1px;
        border-color: $divider-default;
        font-size: 0.875rem;
        font-weight: 400;
        vertical-align: top;
      }
    }

    .p-datatable-emptymessage {
      td {
        padding: 3rem 1rem;
        text-align: center;
      }
    }
  }

  .p-paginator {
    border-bottom: none;
    justify-content: flex-start;
    color: $text-primary;
    padding: 2rem 0.75rem;

    .p-paginator-element {
      max-width: 2.75rem;
      min-width: 2.75rem;
      max-height: 2.75rem;
      min-height: 2.75rem;
    }

    .p-paginator-page {
      margin: 0;
      color: $text-primary;
      border-radius: 0.5rem;

      &.p-highlight {
        background: rgba($text-accent, 0.08);
        color: $text-accent;
      }

      &:not(.p-highlight):hover {
        background: transparent;
      }
    }

    .p-paginator-dots {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .p-paginator-go {
      margin-left: 1.5rem;
      color: $text-primary;

      .p-inputtext {
        max-width: 2.75rem;
        margin-left: 1rem;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .p-datatable[null] .p-datatable-thead > tr > th[role='columnheader'],
  .p-datatable[null] .p-datatable-tfoot > tr > td[role='columnheader'] {
    display: table-cell !important;
  }

  .p-datatable[null] .p-datatable-tbody > tr > td[role='cell'] {
    display: table-cell !important;
    width: auto !important;
  }

  .p-datatable .p-datatable-tbody > tr {
    & > td {
      border: 1px solid $divider-default !important;
    }

    &:first-child > td {
      border-top-width: 0 !important;
    }
  }

  .p-datatable .p-datatable-tbody > tr > td > .p-column-title {
    display: none !important;
  }
}
