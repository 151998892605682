@import '@styles/variables/colors';

.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 0;
  border-top: 1px solid $divider-default;
  height: 5rem;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    background: $main-grey;
    border-radius: 50%;
    overflow: hidden;

    i {
      color: $text-accent !important;
    }

    .skeleton {
      height: 100% !important;
    }
  }

  .info {
    margin-right: 1rem;

    .status {
      margin-left: 0.5rem;
      display: inline-flex;
      align-items: center;
      padding: 0.125rem 0.5rem;
      border-radius: 0.25rem;

      span {
        margin-left: 0.375rem;
        font-size: 0.75rem;
        line-height: 1.25rem;
        white-space: nowrap;
      }

      &.error {
        background: rgba($main-warning, 0.08);

        i {
          color: $main-warning !important;
        }
      }

      &.success {
        background: rgba($main-success, 0.08);

        i {
          color: $main-success !important;
        }
      }

      &.pending {
        background: rgba($main-primary, 0.08);

        i {
          color: $main-primary !important;
        }
      }
    }

    .skeleton:last-child {
      margin-top: 0.5rem;
      width: 12rem !important;
    }
  }

  .skeleton:last-of-type {
    width: 6rem !important;
  }
}
