@import '@styles/variables/colors';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  &.screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;

    svg {
      width: 6rem;
      height: 6rem;
    }
  }

  &.full {
    width: 100%;
    height: 100%;
  }

  svg {
    width: 3rem;
    height: 3rem;

    circle {
      stroke: $main-primary !important;
    }
  }
}
