.collapse {
  button i {
    transition: transform 0.15s ease-in;
    margin-left: 0.5rem !important;
  }

  &.expanded {
    button i {
      transform: rotate(180deg);
      transition: transform 0.15s ease-out;
      margin-top: 0.15rem;
    }
  }
}
