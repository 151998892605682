.dcs-select__control {
  min-height: 2.75rem !important;
  padding: 0.625rem 1rem 0.625rem 0.75rem;
  border-width: 0 !important;
  border-radius: 0.375rem !important;
  background-color: $form-default !important;
  box-shadow: none !important;

  &.dcs-select__control--is-focused {
    .icon-chevron-down {
      transform: rotate(180deg);
    }
  }

  .dcs-select__value-container {
    padding: 0;

    .dcs-select__placeholder {
      font-size: 0.875rem;
      margin: 0;
    }

    .dcs-select__input-container {
      margin: 0;
      padding: 0;

      .dcs-select__input {
        color: $text-primary;
      }
    }
  }

  .dcs-select__indicators {
    margin-left: 0.75rem;

    .icon-chevron-down {
      transition: transform 0.15s ease;
    }

    .dcs-select__indicator-separator {
      display: none;
    }

    .dcs-select__indicator {
      padding: 0;
    }
  }
}

.dcs-select__menu-portal {
  z-index: 3000 !important;
}

.dcs-select__menu {
  box-shadow: 0px 4px 24px rgba(24, 33, 43, 0.04) !important;
  border-radius: 0.5rem !important;
  background: $main-white !important;
  margin: 0.25rem 0 !important;

  .dcs-select__menu-list {
    padding: 0.75rem 0.5rem;

    .dcs-select__menu-notice {
      color: $text-third;
    }

    .dcs-select__option {
      color: $text-primary;
      border-radius: 0.375rem;

      &:active,
      &:focus,
      &:hover {
        background: $form-default;
      }

      &.dcs-select__option--is-focused {
        background: $form-default;
      }

      &.dcs-select__option--is-selected {
        color: $text-accent;
        background: $form-default;
      }

      &.dcs-select__option--is-disabled {
        background: transparent;
        opacity: 0.4;
      }
    }
  }
}
