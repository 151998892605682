@import '@styles/variables/colors';

.subtitle {
  position: relative;
  margin-bottom: 1rem;
  padding: 1rem 0;
  background: $main-grey;
  font-weight: 500;
  color: $text-third;
  z-index: 0;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: -2.5rem;
    width: calc(100% + 5rem);
    height: 100%;
    background: $main-grey;
    z-index: -1;
  }
}
