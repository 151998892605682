@import '@styles/variables/colors';

.layout {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15% 2rem;
  background-color: $main-primary;
  text-align: center;
  overflow-y: hidden;
  z-index: 1;

  &::after {
    position: absolute;
    content: '';
    bottom: -10%;
    left: 0;
    height: 60%;
    width: 100%;
    background: url(./404.svg);
    background-size: 100vw;
    background-repeat: no-repeat;
    background-position: top center;
    z-index: -1;
  }

  .back {
    margin-top: 2.5rem;
    background-color: $main-white;
    color: $text-accent;

    &:hover {
      background-color: $main-white !important;
      color: $text-accent !important;
    }
  }
}
