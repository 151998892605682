// Main
$main-primary: #2556bf;
$main-secondary: #f1abb7;
$main-white: #ffffff;
$main-grey: #f2f5f7;
$main-success: #12b212;
$main-error: #e44646;
$main-warning: #f6a200;

// Text
$text-primary: #2b3949;
$text-secondary: #546880;
$text-third: #93a3b4;
$text-accent: #2556bf;

// Background
$background-main: #f6f6f6;

// Form
$form-default: #f2f5f7;

// Divider
$divider-default: #e6e9ed;

// Icons
$icons-ghost: #b8c1cc;
$icons-active: #818c99;

// Buttons
$button-primary-hover: #1a4193;
$button-primary-focus: #122b62;
$button-secondary-hover: #f2f5f7;
$button-secondary-focus: #e5e9ec;
$button-help-hover: #d77c8b;
$button-help-focus: #af626f;
$button-danger-hover: #d43c3c;
$button-danger-focus: #a42727;
