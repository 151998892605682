.p-inputtext {
  height: 2.75rem;
  background: $form-default;
  color: $text-primary;
  border: 2px solid transparent;
  padding: 0.5rem 1rem 0.5rem 0.75rem;
  line-height: 1.5rem;
  max-width: 100%;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 100px $form-default inset !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover {
    border-color: $form-default !important;
  }

  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    border-color: $main-primary !important;
  }

  &.p-inputtextarea {
    height: auto;
    min-height: 2.75rem;
  }

  &::placeholder {
    color: $text-third;
  }

  &:hover {
    border-color: transparent !important;
  }

  &:focus,
  &:active {
    box-shadow: none !important;
    border-color: $main-primary !important;
  }

  &:read-only {
    pointer-events: none;
  }
}

.p-calendar {
  .p-inputtext {
    padding-right: 2.75rem;
    border-radius: 0.375rem;
  }

  .p-datepicker-trigger {
    position: absolute;
    top: 2px;
    right: 2px;
    min-width: calc(2.75rem - 2px);
    min-height: calc(2.75rem - 0.25rem);
    max-width: calc(2.75rem - 2px);
    max-height: calc(2.75rem - 0.25rem);
    background: $form-default;
    padding-right: 1rem;
    padding-left: 0.75rem;

    &:hover {
      background: $form-default !important;
    }

    &:focus,
    &:active {
      background: $form-default !important;
    }

    span {
      display: none;
    }
  }
}
