@import '@styles/variables/colors';

.layout {
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $main-grey;

  .card {
    width: 25.625rem;
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    background: $main-white;
    border-radius: 0.75rem;
    box-shadow: 0px 4px 24px rgba(24, 33, 43, 0.04);
  }
}
