.main-primary {
  color: $main-primary !important;
}

.main-secondary {
  color: $main-secondary !important;
}

.main-white {
  color: $main-white !important;
}

.main-grey {
  color: $main-grey !important;
}

.main-success {
  color: $main-success !important;
}

.main-error {
  color: $main-error !important;
}

.main-warning {
  color: $main-warning !important;
}

.text-primary {
  color: $text-primary !important;
}

.text-secondary {
  color: $text-secondary !important;
}

.text-third {
  color: $text-third !important;
}

.text-accent {
  color: $text-accent !important;
}

.form-default {
  color: $form-default !important;
}

.divider-default {
  color: $divider-default;
}

.icons-ghost {
  color: $icons-ghost;
}

.pre-line {
  white-space: pre-line;
}
