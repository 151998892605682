.wrapper {
  display: flex;
  align-items: center;

  &.withTitle {
    .logo {
      width: 2.5rem;
      height: 2.5rem;
      padding: 2.5px;
    }
  }

  .title {
    margin: 0;
    margin-left: 0.75rem;
    text-transform: uppercase;
  }

  .logo {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 16px 16px rgba(25, 67, 115, 0.16));
  }
}
