.p-tabview {
  margin-bottom: 1.5rem;

  .p-tabview-panels {
    padding: 0;
  }

  .p-tabview-nav-container {
    position: relative;

    &::after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      width: 100%;
      height: 1px;
      background: $divider-default;
    }

    .p-tabview-nav-btn {
      display: none;
      border-bottom: 1px solid $divider-default;
      color: $text-accent;
      box-shadow: none !important;

      span {
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  .p-tabview-nav {
    border: none;

    .p-unselectable-text {
      position: relative;

      &:not(:first-child) {
        margin-left: 1.5rem;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        .p-tabview-nav-link {
          border-color: $divider-default;
          color: $text-primary;
          transition: color 0.1s ease-in;
        }
      }

      .p-tabview-nav-link {
        padding: 0;
        padding-bottom: 0.75rem;
        font-weight: 400;
        font-size: 0.875rem;
        color: $text-third;
        border: none;
        margin: 0;
        margin-bottom: 1px;
        transition: color 0.1s ease-out;

        &:not(.p-disabled):focus {
          box-shadow: none;
        }
      }

      &::after {
        display: none;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: $text-accent;
        z-index: 1;
      }

      &.p-highlight {
        &::after {
          display: block;
        }

        .p-tabview-nav-link {
          color: $text-primary;
          font-weight: 500;
        }
      }
    }

    .p-tabview-ink-bar {
      background: $text-accent;
      display: none;
    }
  }
}
