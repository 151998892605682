.image {
  min-width: 8rem;
  max-width: 8rem;
  min-height: 8rem;
  border-radius: 0.75rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.overlay {
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none;
  transition: background 0.15s ease;

  &.visible {
    background: rgba(0, 0, 0, 0.4);
    pointer-events: all;
    transition: background 0.2s ease;

    img {
      transform: scale(1);
      transition: transform 0.3s ease;
    }
  }

  img {
    max-width: 100vw;
    max-height: 100vh;
    transform: scale(0);
    pointer-events: none;
  }
}
