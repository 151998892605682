@import '@styles/variables/colors';

.container {
  max-width: 42rem;
  padding: 0.75rem 1rem;
  background-color: rgba($main-warning, 0.1);
  border-radius: 0.375rem;

  .reason {
    padding-left: 1.75rem;
    font-size: 0.75rem;
    color: $text-secondary;
  }
}
