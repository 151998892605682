@import 'react-toastify/dist/ReactToastify.css';

.Toastify__toast-container {
  max-width: 25rem;
  min-width: 18rem;
  width: auto;
  padding: 0;

  &.Toastify__toast-container--top-right {
    top: 2rem;
    right: 2rem;
  }

  .Toastify__toast {
    background-color: $main-white;
    box-shadow: 0px 6px 24px rgba(24, 33, 43, 0.08);
    border-radius: 0.375rem;
    border: 1px solid $divider-default;
    padding: 0.75rem 1rem;
    cursor: default;

    .Toastify__toast-body {
      padding: 0;
      align-items: flex-start;

      .Toastify__toast-icon {
        width: auto;
        margin-right: 0.75rem;
        margin-top: 0.25rem;
      }
    }
  }
}
