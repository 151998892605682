@import '@styles/variables/colors';

.card {
  padding: 0.75rem 0.5rem;
  background: $main-white;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 24px rgba(24, 33, 43, 0.04);

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.375rem;
    padding: 0.5rem 0.75rem;
    transition: background 0.15s ease-out;
    cursor: pointer;

    &:hover {
      background: $form-default;
      transition: background 0.1s ease-in;
    }

    i {
      transition: color 0.15s ease-out;
    }

    &:not(.active) {
      &:hover {
        i {
          color: $icons-active;
          transition: color 0.1s ease-in;
        }
      }
    }

    &.active {
      color: $main-primary;

      i {
        color: $main-primary;
      }
    }
  }
}
