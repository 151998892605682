@import '@styles/variables/colors';

.container {
  border-radius: 0.5rem;
  background: $main-white;

  .item {
    position: relative;
    display: flex;
    align-items: center;

    span {
      position: relative;
      display: inline-block;
      background: $main-white;
      z-index: 1;

      &:first-child {
        width: 5.5rem;
        margin-right: 1.5rem;
        color: $text-third;
      }
    }

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }

  &.interactive {
    width: 27rem;
    padding: 1.5rem;
    border: 1.5px solid $divider-default;

    .item {
      justify-content: space-between;

      span {
        &:first-child {
          padding-right: 0.5rem;
          width: auto;

          &::after {
            display: none;
          }
        }

        &:last-child {
          padding-left: 0.5rem;
        }
      }

      &::after {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 1.5px;
        background-image: linear-gradient(
          to right,
          $divider-default 33%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: bottom;
        background-size: 1.125rem 1.5px;
        background-repeat: repeat-x;
      }
    }
  }
}
