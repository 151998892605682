@import '@styles/variables/colors';

.wrapper {
  display: inline-flex;

  label {
    width: 5.5rem;
    margin-right: 1.5rem;
    font-size: 1rem !important;
    color: $text-third !important;
  }

  &.column {
    flex-direction: column;

    label {
      width: auto;
      margin-right: 0;
      margin-bottom: 0.25rem;
      font-size: 0.875rem !important;
      color: $text-secondary !important;
    }
  }
}
