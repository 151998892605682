.p-sidebar-right {
  .p-sidebar {
    width: 30rem;
  }
}

.p-sidebar {
  padding: 0;
  color: $text-primary;

  .p-sidebar-header {
    display: none;
  }

  .p-sidebar-content {
    padding: 3rem !important;
    overflow-x: hidden;
  }
}
