.m-extra1 {
  margin: 0.75rem !important;
}

.mt-extra1 {
  margin-top: 0.75rem !important;
}

.mr-extra1 {
  margin-right: 0.75rem !important;
}

.mb-extra1 {
  margin-bottom: 0.75rem !important;
}

.ml-extra1 {
  margin-left: 0.75rem !important;
}

.my-extra1 {
  margin: 0.75rem 0;
}

.mx-extra1 {
  margin: 0 0.75rem;
}

.m-extra2 {
  margin: 1.25rem !important;
}

.mt-extra2 {
  margin-top: 1.25rem !important;
}

.mr-extra2 {
  margin-right: 1.25rem !important;
}

.mb-extra2 {
  margin-bottom: 1.25rem !important;
}

.ml-extra2 {
  margin-left: 1.25rem !important;
}

.my-extra2 {
  margin: 1.25rem 0;
}

.mx-extra2 {
  margin: 0 1.25rem;
}

.p-extra1 {
  padding: 0.75rem !important;
}

.pt-extra1 {
  padding-top: 0.75rem !important;
}

.pr-extra1 {
  padding-right: 0.75rem !important;
}

.pb-extra1 {
  padding-bottom: 0.75rem !important;
}

.pl-extra1 {
  padding-left: 0.75rem !important;
}

.py-extra1 {
  padding: 0.75rem 0;
}

.px-extra1 {
  padding: 0 0.75rem;
}

.p-extra2 {
  padding: 1.25rem !important;
}

.pt-extra2 {
  padding-top: 1.25rem !important;
}

.pr-extra2 {
  padding-right: 1.25rem !important;
}

.pb-extra2 {
  padding-bottom: 1.25rem !important;
}

.pl-extra2 {
  padding-left: 1.25rem !important;
}

.py-extra2 {
  padding: 1.25rem 0;
}

.px-extra2 {
  padding: 0 1.25rem;
}
